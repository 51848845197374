@import url('https://fonts.googleapis.com/css2?family=Chango&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Chango', sans-serif;
  }
}